import styled from "@emotion/styled";
import { TextField as MuiTextField } from "@mui/material";
import { spacing } from "@mui/system";
import React from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import InputMask, { Props } from "react-input-mask";

const TextField = styled(MuiTextField)(spacing);

export function FormTextField<TFieldValues extends FieldValues = FieldValues>({
  InputMaskProps,
  ...props
}: UseControllerProps<TFieldValues> &
  React.ComponentProps<typeof TextField> & {
    InputMaskProps?: Props;
  }) {
  const {
    field: { onChange, onBlur, name, value, ref },
    fieldState: { error },
  } = useController(props);

  if (InputMaskProps) {
    return (
      <InputMask
        {...InputMaskProps}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        value={value}
        inputRef={ref}
      >
        <TextField
          {...props}
          error={Boolean(error)}
          {...(error?.message ? { helperText: error.message } : {})}
        />
      </InputMask>
    );
  }

  return (
    <TextField
      {...props}
      onChange={onChange}
      onBlur={onBlur}
      name={name}
      value={value}
      inputRef={ref}
      error={Boolean(error)}
      {...(error?.message ? { helperText: error.message } : {})}
    />
  );
}
