import styled from "@emotion/styled";
import {
  CardContent,
  Grid,
  InputAdornment,
  Button as MuiButton,
  Card as MuiCard,
  TextField as MuiTextField,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import React from "react";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EnhancedTable, HeadCell } from "../../components/EnhancedTable";
import {
  getParticipantLimits,
  getParticipantUserLimits,
  setParticipantLimits,
} from "../../services/participants";
import {
  ParticipantLimits,
  ParticipantRegistrationData,
} from "../../types/entities/participants";
import { ApiError } from "../../types/error";
import { formatCPF } from "../../utils/string";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const Button = styled(MuiButton)(spacing);

interface LimitsType {
  currency: string;
  operationalTotalLimit: number;
  operationalTradingLimit: number;
  sellTotalGranted: number;
  sellConsumed: number;
}
interface Inputs {
  currency: string;
  operationalTotalLimit: string;
  operationalTradingLimit: string;
  sellTotalGranted: string;
  sellConsumed: string;
  usersLimits: {
    id: number;
    limit: string;
  }[];
}

const limitsDataDefaultValues: Inputs = {
  currency: "BRL",
  operationalTotalLimit: "0",
  operationalTradingLimit: "0",
  sellTotalGranted: "0",
  sellConsumed: "0",
  usersLimits: [],
};

const initialLimitsData: LimitsType = {
  currency: "BRL",
  operationalTotalLimit: 0,
  operationalTradingLimit: 0,
  sellTotalGranted: 300000,
  sellConsumed: 19998.73,
};

interface OperatingLimitsCardProps {
  participantId: string;
  participantData: ParticipantRegistrationData;
}

export default function OperatingLimitsCards({
  participantId,
  participantData,
}: OperatingLimitsCardProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const submitLimits = useMutation<void, unknown, ParticipantLimits>(
    (data) => setParticipantLimits(participantId, data),
    {
      onSuccess: (data) => {
        // console.log("success");
        // queryClient.invalidateQueries(["/participants"]);
        // if (data.participantId) {
        //   navigate(`/participants/edit/${data.participantId}`, { replace: true });
        // } else {
        //   navigate(`/participants`);
        // }
        // enqueueSnackbar(t("The operation completed successfully"), {
        //   variant: "success",
        // });
      },
      onError: (e) => {
        enqueueSnackbar(
          t((e as ApiError | null)?.error ?? "Something went wrong."),
          {
            variant: "error",
          }
        );
      },
    }
  );

  const {
    data: limits,
    isLoading,
    isError,
  } = useQuery(["/participant-limits", participantId], () =>
    getParticipantLimits(participantId)
  );

  const {
    data: usersLimits,
    // isLoading,
    // isError,
  } = useQuery(["/participant-users/limits", participantId], () =>
    getParticipantUserLimits(participantId)
  );

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: limitsDataDefaultValues });

  const [minimumFractionDigits, maximumFractionDigits, currencySymbol] =
    React.useMemo(() => {
      const { minimumFractionDigits, maximumFractionDigits } =
        new Intl.NumberFormat(language, {
          style: "currency",
          currency: initialLimitsData.currency,
        }).resolvedOptions();

      const currencySymbol = (0)
        .toLocaleString(language, {
          style: "currency",
          currency: initialLimitsData.currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, "")
        .trim();

      return [minimumFractionDigits, maximumFractionDigits, currencySymbol];
    }, [language]);

  const formatCurrency = React.useCallback(
    (value: string) => {
      const numberValue =
        Number(value.replace(/\D/g, "")) / 10 ** minimumFractionDigits;
      return numberValue.toLocaleString(language, {
        maximumFractionDigits,
        minimumFractionDigits,
      });
    },
    [language, maximumFractionDigits, minimumFractionDigits]
  );

  React.useEffect(() => {
    if (!limits) {
      return;
    }

    setValue("currency", initialLimitsData.currency);

    setValue(
      "operationalTotalLimit",
      limits.totalOperatingLimit.toLocaleString(language, {
        maximumFractionDigits,
        minimumFractionDigits,
      })
    );

    setValue(
      "operationalTradingLimit",
      limits.tradingOperatingLimit.toLocaleString(language, {
        maximumFractionDigits,
        minimumFractionDigits,
      })
    );

    if (!usersLimits) {
      return;
    }

    setValue(
      "usersLimits",
      usersLimits.map((user) => ({
        id: user.id,
        limit: formatCurrency(user.totalOperatingLimit * 100 + ""),
      }))
    );
  }, [
    usersLimits,
    limits,
    language,
    maximumFractionDigits,
    minimumFractionDigits,
    setValue,
    formatCurrency,
  ]);

  const onSubmit = React.useCallback<SubmitHandler<Inputs>>(
    async (data) => {
      const payload = {
        totalOperatingLimit: parseFloat(
          data.operationalTotalLimit.replaceAll(".", "").replace(",", ".")
        ),
        tradingOperatingLimit: parseFloat(
          data.operationalTradingLimit.replace(".", "").replace(",", ".")
        ),
        userLimits: data.usersLimits.map((userLimits) => {
          return {
            totalOperatingLimit: parseFloat(
              userLimits.limit.replace(".", "").replace(",", ".")
            ),
            userId: userLimits.id,
          };
        }),
      };

      if (
        payload.userLimits.reduce((ac, curr) => {
          return ac + curr.totalOperatingLimit;
        }, 0) > payload.totalOperatingLimit
      ) {
        enqueueSnackbar(t("User Limits Cannot Exced Participant Limit"), {
          variant: "error",
        });
        return;
      }
      await submitLimits.mutate(payload);
      enqueueSnackbar(t("The operation completed successfully"), {
        variant: "success",
      });
    },
    [enqueueSnackbar, t]
  );

  const onInvalidSubmit = React.useCallback<SubmitErrorHandler<Inputs>>(() => {
    enqueueSnackbar(t("You must fill in all required fields"), {
      variant: "error",
    });
  }, [enqueueSnackbar, t]);

  const headCells = React.useMemo<Array<HeadCell>>(
    () => [
      { id: "fullName", alignment: "left", label: t("Name") },
      { id: "cpf", alignment: "left", label: t("CPF") },
      { id: "email", alignment: "left", label: t("Email") },
      { id: "totalLimit", alignment: "left", label: t("User Total Limit") },
      {
        id: "consumedLimit",
        alignment: "left",
        label: t("User Consumed Limit"),
      },
      // { id: "actions", alignment: "right", label: t("Actions") },
    ],
    [t]
  );

  return (
    <Card mb={6}>
      <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <CardContent>
          <Typography variant="h6" gutterBottom mb={4}>
            {t("Company Operational Limits")}
          </Typography>

          <Grid container spacing={[0, 6]}>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name={"operationalTotalLimit"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      if (e.target.value.length > 16) {
                        return;
                      }
                      field.onChange(formatCurrency(e.target.value));
                    }}
                    label={t("Total Operational Limit")}
                    variant="outlined"
                    fullWidth
                    my={2}
                    error={!!errors.operationalTotalLimit}
                    helperText={errors.operationalTotalLimit?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {currencySymbol}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={"operationalTradingLimit"}
                rules={{
                  required: {
                    value: true,
                    message: t("This field is required"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    onChange={(e) => {
                      if (e.target.value.length > 14) {
                        return;
                      }
                      field.onChange(formatCurrency(e.target.value));
                    }}
                    label={t("Trading Operational Limit")}
                    variant="outlined"
                    fullWidth
                    my={2}
                    error={!!errors.operationalTotalLimit}
                    helperText={errors.operationalTotalLimit?.message}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {currencySymbol}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardContent>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <EnhancedTable
                title={t("Limits Per User")}
                data={usersLimits || []}
                headCells={headCells}
                keyExtractor={(row) => String(row.id)}
                renderRow={(row, index) => {
                  return (
                    <TableRow hover tabIndex={-1}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>

                      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                        {formatCPF(row.cpf) ?? "-"}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">
                        <Controller
                          control={control}
                          name={`usersLimits.${index}.limit`}
                          rules={{
                            required: {
                              value: true,
                              message: t("This field is required"),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              onChange={(e) => {
                                if (e.target.value.length > 14) {
                                  return;
                                }
                                field.onChange(formatCurrency(e.target.value));
                              }}
                              variant="outlined"
                              size="small"
                              error={!!errors.operationalTotalLimit}
                              helperText={errors.operationalTotalLimit?.message}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {currencySymbol}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell align="left">
                        R$ {formatCurrency(row.operatingLimitConsumed + "")}
                      </TableCell>
                      {/* <TableCell padding="none" align="right">
                        <Box mr={2} my={2} whiteSpace="nowrap">
                          <Tooltip title={t("Edit")}>
                            <IconButton
                              aria-label="edit"
                              size="large"
                              // onClick={() => handleOpen(String(row.id))}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell> */}
                    </TableRow>
                  );
                }}
              />
            </Grid>
          </Grid>
        </CardContent>

        <Button variant="contained" color="primary" mt={3} type="submit">
          {t("Save")}
        </Button>
      </form>
    </Card>
  );
}
